import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

const APP_TITLE = "qReview";
@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private _page!: string;
  private _resource!: string;
  fullTitle!: string;
  pageTitle!: string;

  loading = false;

  set page(page: string) {
    this._page = page;
    this.refreshBrowserTitle();
  }

  get page(): string {
    return this._page;
  }

  set resource(resource: string) {
    this._resource = resource;
    this.refreshBrowserTitle();
  }

  get resource(): string {
    return this._resource;
  }

  constructor(private title: Title) {
  }

  setTitle(page?: string, resource?: string) {
    this._page = page ? page : '';
    this._resource = resource ? resource : '';
    this.refreshBrowserTitle();
  }

  refreshBrowserTitle() {
    this.pageTitle = [this.page, this.resource].filter(part => !!part).join(': ');
    this.fullTitle = [this.pageTitle, APP_TITLE].filter(part => !!part).join(' - ');
    this.title.setTitle(this.fullTitle);
  }
}
